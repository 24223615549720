// React Leaflet
import { Popup, Marker, Tooltip, useMap, useMapEvents } from "react-leaflet"
import MarkerClusterGroup from 'react-leaflet-cluster'

import * as L from 'leaflet'

// Redux
import * as trackingActions from '../../store/actions/tracking';

// Redux
import { connect } from 'react-redux'

import PopupContent from './Marker/PopupContent'

import { IgnitionStatusSVGData } from './IgnitionStatus'
import { useCallback, useEffect, useMemo, useState } from "react";
import { useRef } from "react";
import { makeStyles } from "@mui/styles";
import { createPlutoLogger } from "../../utilities/Common";

import MyLocation from "../Map/MyLocation";
import FitButton from "../Map/FitButton";
import useChangeHandler from "./useStoredSelectedTargetsWithPosition";
import DebugTarget from "./DebugTarget";
import { useAppControl } from "../App/AppControlProvider";
import { useMediaQuery } from "@mui/system";
import useMediaQueryTracker from "./useMediaQueryTracker";


const MARKER_ICON_SIZE = 28
const CLUSTER_ICON_SIZE = 36

const useStyles = makeStyles((theme) => ({
    cluster: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: "50%",
        border: `1px solid ${theme.palette.primary.dark}`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: `${theme.palette.primary.contrastText}`,
        fontWeight: "bold",
        boxShadow: theme.shadows[1],
        fontFamily: "\"Exo 2\"", // TODO: check if this is even necessary
    },
}));

const D = createPlutoLogger("[TrackingMapObjects.js]")

const TrackingMapObjects = (props) => {
    
    // Old Redux
    const { state } = props
    const { setPopupTargetId } = props
    const { tracking } = state
    const selectedTargets = tracking.targets.map((e) => {
        return {id: e.target.id, ...e}
    })

    // This function checks if the given position is a valid latitude and longitude pair.
    // We need to check this because the server may send invalid data. We do check in the saga as well, but
    // for the sake of safety, we check it here as well.
    function isValidLatLng(position) {
        if (
            position && 
            typeof position.latitude === 'number' &&
            typeof position.longitude === 'number' &&
            !isNaN(position.latitude) &&
            !isNaN(position.longitude) &&
            position.latitude >= -90 && 
            position.latitude <= 90 &&
            position.longitude >= -180 &&
            position.longitude <= 180 &&
            position.latitude !== 0 &&
            position.longitude !== 0
        ) {
            return true
        }
        return false
    }
    const getTargetsWithCurrentPosition = () => selectedTargets.filter(t => {
        const cp = t.currentPosition
        if (!isValidLatLng(cp)) {
            return false
        }
        return cp
    })
    
    const selectedTargetsWithPosition = getTargetsWithCurrentPosition()  
    const coordinates = selectedTargetsWithPosition.map(target => [target.currentPosition.latitude, target.currentPosition.longitude])
    
    const [debugTargetId, setDebugTargetId] = useState(null)
    const debugTarget = selectedTargetsWithPosition.find(t => t.id === debugTargetId)
    const { getDebugMode } = useAppControl()
    const showDebugTarget = getDebugMode()

    // other
    const clusterGroupRef = useRef(null)
    const classes = useStyles()
    
    const changes = useChangeHandler(selectedTargetsWithPosition)
    D("[changes] Count: ", Object.keys(changes).length)
    D("[changes] ", changes)

    D("Show debug target: ", showDebugTarget)


    return (
    <>
        <MyLocation />
        {showDebugTarget ? <DebugTarget target={debugTarget} /> : null}
        
        <FitButton
            coordinates={coordinates}
            zoomLogicEnabled />

        <MarkerClusterGroup
            ref={clusterGroupRef}
            showCoverageOnHover={false}
            spiderfyDistanceMultiplier={2}
            iconCreateFunction={(cluster) => {
                return L.divIcon({
                    html: `${cluster.getChildCount()}`,
                    className: `${classes.cluster}`,
                    iconSize: [CLUSTER_ICON_SIZE, CLUSTER_ICON_SIZE]
                })
            }}
            >
            {selectedTargetsWithPosition.map((item, index) => {
                return <MarkerWrapper
                key={index}
                item={item}
                changed={changes[item.id]}
                setDebugTargetId={setDebugTargetId}
                setPopupTargetId={setPopupTargetId} />
                /*//const changed = changes ? changes[item.id].changed : false
                D("[TrackingMapObjects.js] Rendering marker: ", item.id, "changed:", changes[item.id] )
                return null;*/
            })}

        </MarkerClusterGroup>
    </>
  )
}

const MarkerWrapper = (props) => {
    const { item, index, changed, setPopupTargetId, setDebugTargetId } = props

    const map = useMap()
    const clickTimeout = useRef(null)

    const createMarkerIcon = (color) => {
        return L.divIcon({
                html: IgnitionStatusSVGData(MARKER_ICON_SIZE, MARKER_ICON_SIZE, color),
                iconSize: [MARKER_ICON_SIZE, MARKER_ICON_SIZE],
                iconAnchor: [MARKER_ICON_SIZE / 2, MARKER_ICON_SIZE / 2],
        });
    }

    // Double-click event handler
    const handleMarkerDoubleClick = useCallback((event, item) => {
        
        D("[TrackingMapObjects.js] Marker double clicked. Target id: ", item.target.id)

        map.setView([item.currentPosition.latitude, item.currentPosition.longitude], 16)
        
        // Clear the click timeout to prevent single-click action
        if (clickTimeout.current) {
            clearTimeout(clickTimeout.current);
            clickTimeout.current = null;
        }
    }, [map]);

    // Click event handler
    const handleMarkerClick = useCallback((event, item) => {

        setDebugTargetId(item.target.id)

        // There is a strange bug in the Leaflet library that causes dblclick event does not fire if the click event is not prevented.
        // This is a workaround to let time to the dblclick event to fire.
        // Set a timeout to delay the single-click action
        clickTimeout.current = setTimeout(() => {
            D("Marker clicked. Target id: ", item.target.id)
            setPopupTargetId(item.target.id);
            clickTimeout.current = null;
        }, 300);
    }, [setPopupTargetId]);

    const mediaQueryStates = useMediaQueryTracker()
    D("[TrackingMapObjects.js] [mediaQuery] Did media query change: ", mediaQueryStates)

    return useMemo(() => {
        D("[TrackingMapObjects.js] [markerRender] Rendering marker: ", item.id, "changed:", changed )
        return (<Marker
            icon={createMarkerIcon(item.currentPosition.color)}
            key={`${item.id}-${index}`}
            position={[item.currentPosition.latitude, item.currentPosition.longitude]}
            eventHandlers={{
                click: (event) => handleMarkerClick(event, item),
                dblclick: (event) => handleMarkerDoubleClick(event, item),
            }}>
``
            <Popup interactive={true} onClick={() => D("Popup opened")}>
                <PopupContent item={item} onClose={()=>{}} onRefresh={()=>{}} />
            </Popup>

            <Tooltip direction="bottom" offset={[0, 12]} opacity={1} permanent>
                {item.target.name}
            </Tooltip>
        
        </Marker>)
    }, [changed, mediaQueryStates])
}

const mapDispatchToProps = dispatch => {
    return {     
      // Target
      setPopupTargetId: (targetId) => dispatch(trackingActions.setPopupTargetId(targetId)),
    }
  }
  
const mapStateToProps = (state) => {
    return {
        state: state,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TrackingMapObjects)