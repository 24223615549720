// React imports
import React, {useEffect, useState} from 'react'

// Redux imports
import { connect } from 'react-redux'


import * as chatActions from '../../store/actions/chat'
import { useTranslation } from 'react-i18next'
import TextSearch from '../Filter/TextSearch'
import { makeStyles } from '@mui/styles'
import { CircularProgress, FormControl } from '@mui/material'
import { NAVIGATION_ROUTE_CHAT } from '../BottomMenu/NavigationRoutes'
import { clsx, createPlutoLogger } from '../../utilities/Common'
import { useLocation } from 'react-router-dom'

const D = createPlutoLogger("💬 [ChatFilter.js]")

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    gap: theme.spacing(1),
    padding: theme.spacing(1),
  },
  filterContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& > div": {
      width: "100%",
    }
  },
  listContainer: {
    position: "relative",
    flex: 1,
  },
  listWrapper: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    overflowY: "auto",
  },
  progressContainer: {
    visibility: "hidden",
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    overflow: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255, 255, 255, .3)",
    zIndex: 1,
  },
  visible: {
    visibility: "visible",
  },
  filterInput: {
    boxSizing: "border-box",
    fontSize: "135%",
    backgroundColor: "green",
  },   
  selected: {
    fontWeight: "bold",
    "& div": {
      color: theme.palette.secondary.main,
    }
  },
  normal: {
    "& div": {
      color: theme.palette.grey[900],
    },
    "& > div:first-child": {
      // no specific sytle at the moment
    },
    "& > div:nth-child(2)": {
      fontSize: "90%"
    }
  },
  odd: {
    backgroundColor: "white"    
  },
  even: {
    backgroundColor: theme.palette.grey[200]
  }
}));

const ChatFilter = (props) => {

    // old Redux
    const { state } = props
    const { chat }  = state
    const { threads, filteringInProgress } = chat
    const selectedThreadId = chat.selectedThreadId

    const location = useLocation()
    const currentModule = location.pathname

    // Forced thread call imidiatelly when the user navigates to the chat module
    useEffect(() => {
        
        const needLoadThreads = currentModule === NAVIGATION_ROUTE_CHAT

        if (needLoadThreads) {
            D("[useEffect] Loading threads.")
            props.getThreads()
        }

        return () => {
            D("[useEffect] cleanup.")
        }

    }, [currentModule])

    // others
    const classes = useStyles()
    const { t } = useTranslation()

    const [filterBuffer, _setFilterBuffer] = useState({
      text: chat.filter,
      processed: true,
    })

    const truncate = (str, n) => (str.length > n) ? str.substr(0, n-1) + '…' : str

    const setFilterBuffer = (text, processed) => {
      _setFilterBuffer({
        text: text || "",
        processed: processed
      })
    } 

    const getLastMessage = (messages) => {
      if (!messages || messages.length === 0) return null
      return messages[0].content.body
    }

    const threadList = threads.map(t => ({
      name: `${t.threadUser.name}`,
      lastMessage: getLastMessage(t.messages),
      id: t.id
    }))

    const handleFilterChange = (evt) => {
      setFilterBuffer(evt.target.value, false)
      //props.changeFilter(evt.target.value)
    }

    const handleClearFilter = (evt) => {
      setFilterBuffer("", false)
      //props.changeFilter("")
    }

    const handleTabClick = (threadId) => props.setSelectedThreadId(threadId)

    const checkBuffer = () => {

      if (filterBuffer.processed) return // In this case, we do not need to do anything

      props.changeFilter(filterBuffer.text)
      setFilterBuffer(filterBuffer.text, true)
    }

    useEffect(() => {
      const bufferInterval = setInterval(checkBuffer, 250)
      return () => clearInterval(bufferInterval)
    // eslint-disable-next-line
    },[threadList])

    const progressContainerClasses = clsx({
      [classes.progressContainer]: true,
      [classes.visible]: filteringInProgress
    })

    D("Rendering...")

    return(
        <div className={classes.root}>
          <div className={classes.filterContainer}>
            <FormControl>
              <TextSearch
                className={classes.textSearch}
                filter={filterBuffer.text}
                onChange={handleFilterChange}
                onClear={handleClearFilter}
                endAdornment={null}
                placeholder={t("chat.textSearch.placeholder")}/>
            </FormControl>
          </div>
          <div className={classes.listContainer}>
              <div className={progressContainerClasses}><CircularProgress /></div>
              <div className={classes.listWrapper}>
                {threadList.map( (t, i) => {
                  return (
                    <div className={`${i % 2 === 0 ? classes.odd : classes.even} ${t.id === selectedThreadId ? classes.selected : classes.normal}`} onClick={(ev) => handleTabClick(t.id)} key={t.id}>
                      <div className={classes.topic}>{truncate(t.name, 50)}</div>
                      <div className={classes.subtopic}>{truncate(t.lastMessage, 100)}</div>                
                    </div>
                  )
                })}
              </div>
          </div>
        </div>
    )
}

function mapStateToProps(state) {
  return {
    state: state
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setSelectedThreadId: (payload) => dispatch(chatActions.setSelectedThreadId(payload)),
    getThreads: () => dispatch(chatActions.getThreads()),
    changeFilter: (payload) => dispatch(chatActions.changeFilter(payload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatFilter)