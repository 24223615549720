// Redux
import { connect } from 'react-redux'
import * as trackingActions from '../../../store/actions/tracking'

import { AccessTime, HourglassEmpty, Place, PlaceOutlined, Refresh, Speed } from "@mui/icons-material"

import Measures from "../Measures"
import CopyPosition from "./CopyPosition"
import { IgnitionStatusIcon } from '../IgnitionStatus'

import * as COM from '../../../utilities/Common'
import { makeStyles } from '@mui/styles'
import { useEffect, useState } from 'react'
import { useAppControl } from '../../App/AppControlProvider'

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: "left",
    color: theme.palette.primary.main,
  },
  /*  const is320 = useMediaQuery('(max-width: 320px)')
  const is400 = useMediaQuery('(min-width: 400px)')
  const is480 = useMediaQuery('(max-width: 480px)')
  const is890 = useMediaQuery('(max-width: 890px)')*/

  // Leaftet popup is absolute positioned, so we have to set the width here
  "@media (max-width: 320px)": {
    root: {
      width: "320px",
    },
  },
  "@media (min-width: 400px)": {
    root: {
      width: "360px",
    },
  },
  "@media (min-width: 480px)": {
    root: {
      width: "440px",
    },
  },
  "@media (min-width: 890px)": {
    root: {
      width: "680px",
    },
  },
  controlsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 0,
    "&: > *": {
      cursor: "pointer",
    },
    marginBottom: ".5rem",
  },
  refreshButton: {
    transform: "scale(80%)",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    gap: ".5rem",
    //backgroundColor: "red", //Just for debug
    fontSize: "10px",
  },
  ignitionDriverSpeedContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    //backgroundColor: theme.palette.grey[200],
    "& > div": {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      fontSize: "15px",
      fontWeight: "bold",
    },
  },
  "@media (min-width: 600px)": {
    ignitionDriverSpeedContainer: {
      flexDirection: "row",
    },
  },
  ingnitionStatus: {
    width: "1.5rem",
    height: "1.5rem",
  },
  speedContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  dateTimeContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    //backgroundColor: "red", //Just for debug
    fontSize: "10.8px !important",
  },
  placeAndLocationContainer: {
    display: "flex",
    flexDirection: "column",
    gap: ".5rem",
    //backgroundColor: "yellow", //just for debug
    fontSize: "15px !important",
  },
  placeContainer: {
    display: "flex",
    justifyContent: "start",
    alignItems: "flex-start",
    fontWeight: "bold",
  },
  locationContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    fontSize: "12px !important",
  },
  copyContainer: {
    height: "1.5rem",
    marginLeft: ".5rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    scale: ".8",
    color: theme.palette.primary.main,
  },
  leftTitle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  medium: {
    marginBottom: ".5rem",
  },
  grey: {
    color: theme.palette.grey[700],
  },
  normal: {
    marginBottom: ".5rem",
    color: theme.palette.primary.main,
  },
  clickable: {
    cursor: "pointer",
  },
}))

const TitleText = (props) => {
  const { target } = props
  if (!target.description) return target.name
  return `${target.name}, ${target.description}`
}


const PopupContent = (props) => {

  const { item } = props

  const classes = useStyles()
  const [refreshStarted, setRefreshStarted] = useState(null)
  const { getDebugMode } = useAppControl()
  const debugMode = getDebugMode()

  const target = item.target
  const currentPosition = item.currentPosition
  const measures = currentPosition.measures

  useEffect(() => {
    const timer = setTimeout(() => {
      if (refreshStarted) {
        setRefreshStarted(null)
      }
    }, 250)
    return () => clearTimeout(timer)
  }, [refreshStarted])

  if (!item) return null

  const handleRefresh = (ev) => {
    ev.stopPropagation()
    setRefreshStarted(true)
    props.setPopupTargetId(target.id)
  }

  const refreshIcon = refreshStarted ? <HourglassEmpty className={classes.refreshButton} /> : <Refresh onClick={handleRefresh} className={classes.refreshButton} />

  // XXX: We have to use different font if the color is grey
  // at the moment we have no other status color field, so we have to use the color field in a condition :(
  // https://jira.idata.hu/browse/PLUTO-1350
  const isIconGrey = currentPosition.color === "#808080"
  const truncatedLat = currentPosition.latitude.toFixed(4)
  const truncatedLng = currentPosition.longitude.toFixed(4)

  const lastTimeMicros = item.lastPositions[0].timeMicros || item.serverTimeMicros
  const frontendTime = COM.createFrontendTimeMilis(lastTimeMicros)
  const dateTimeString = COM.formattedDateTime(frontendTime)
  const speedUnit = process.env.REACT_APP_SPEED_UNIT || 'km/h'

  // Color-related font classes
  const dateTimeClasses = COM.clsx({
    [classes.dateTimeContainer]: true,
    [classes.normal]: !isIconGrey,
    [classes.medium]: isIconGrey,
    [classes.grey]: isIconGrey,
  })

  const lastUIUpdateTime = COM.formattedDateTime(new Date().getTime())
  console.debug(`[PopupContent.js] Popup for ${item.id} has been updated at `, lastUIUpdateTime)

  return (
    <div className={classes.root}>
   
      <div className={classes.controlsContainer}>
          {refreshIcon}
      </div>

      <div className={classes.contentContainer}>

        {debugMode ? <div>Last update: {lastUIUpdateTime}</div> : null}

        <div className={classes.ignitionDriverSpeedContainer}>
          <div>
            <IgnitionStatusIcon color={currentPosition.color} rootClass={classes.ingnitionStatus} />&nbsp;
            <TitleText target={target} />
          </div>
          <div>
            <Speed />&nbsp;{currentPosition.speed}&nbsp;{speedUnit}
          </div>
        </div>
        
        <div className={dateTimeClasses}>
          <AccessTime />&nbsp;{dateTimeString}
        </div>

        <div className={classes.placeAndLocationContainer}>
          <div className={classes.placeContainer}>
              <div><Place /></div>
              <div>{currentPosition.geocodedAddress}</div>
          </div>
          <div className={classes.locationContainer}>
            <small><PlaceOutlined />&nbsp;</small>
            <small>{truncatedLat}, {truncatedLng}</small>
            <div className={classes.copyContainer}><CopyPosition position={currentPosition} /></div>
          </div>
        </div>
      </div>
      
        <Measures measures={measures} />
    </div>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    setPopupTargetId: (targetId) => dispatch(trackingActions.setPopupTargetId(targetId)),
  }
}

const mapStateToProps = (state) => {
  return {
    state: state,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PopupContent)